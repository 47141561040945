<template>
  <div class="modal fade" id="ModalAddProcessor">
    <div class="modal-dialog" style="max-width: 1100px">
      <div class="modal-content">
        <div class="modal-header bg--blue">
          <h5 class="modal-title">{{ $t('lbl_create_processor') }}</h5>
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="bg-white rounded-2xl m-4 pb-10">
            <div class="row">
              <div class="col-xl-4">
                <form class="flex flex-col items-center justify-center">
                  <el-skeleton class="mb-3 mt-4" style="width: 288px">
                    <template slot="template">
                      <img
                        v-if="thumb_file"
                        class="w-72 h-64 object-contain"
                        :src="thumb_file"
                        alt="Logo org"
                      />
                      <el-skeleton-item v-else variant="image" style="width: 288px; height: 256px" />
                    </template>
                  </el-skeleton>

                  <label for="file-upload">
                    <div
                      class="button-add text-white cursor-pointer flex items-center justify-center rounded-lg h-10 px-3 py-2 focus:outline-none"
                    >
                      <div>{{ $t('lbl_add_logo') }}</div>
                    </div>
                  </label>
                  <input
                    id="file-upload"
                    class="d-none"
                    name="upload_cont_img"
                    type="file"
                    accept="image/*"
                    @change="onSelectedThumbFile($event)"
                  />
                </form>
              </div>
              <div class="col-xl-8 mt-4">
                <div class="row mb-2">
                  <div class="col">
                    <label
                      class="text-xl text-black font-medium ml-3 mb-2 required"
                    >{{ $t('lbl_processor_name') }}</label>
                    <input
                      class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                      :placeholder="$t('lbl_enter_processor_name')"
                      v-model="form.company_name"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <label class="text-xl text-black font-medium ml-3 mb-2">{{ $t('lbl_address') }}</label>
                    <input
                      class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                      :placeholder="$t('lbl_enter_address')"
                      v-model="form.contact"
                    />
                  </div>
                  <div class="col-xl-6">
                    <label class="text-xl text-black font-medium ml-3 mb-2">{{ $t('lbl_hotline') }}</label>
                    <input
                      class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                      :placeholder="$t('lbl_enter_phone')"
                      v-model="form.phone_number"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right mx-4 mt-4">
              <button
                v-loading="isLoading"
                @click="handleSubmitOrg"
                class="button-add text-white rounded-lg h-10 w-24 px-3 py-2 border-0 focus:outline-none"
              >{{ $t(isUpdate?'lbl_update':'lbl_create') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import appUtils from '@/utils/appUtils'
import { isNil } from 'lodash'

// const DEFAULT_FORM = {}

export default {
  name: 'ModalAddProcessor',
  props: {
    isUpdate: Boolean,
    supplierInfo: Object,
    processorInfo: Object
  },
  data () {
    return {
      appUtils,
      thumb_file: '',
      imgPut: '',
      form: {},
      isLoading: false
    }
  },
  mounted () {
    if (this.processorInfo) {
      this.handleMapData()
    }
  },
  watch: {
    processorInfo () {
      this.handleMapData()
    }
  },
  methods: {
    onSelectedThumbFile (event) {
      const file = event.target.files[0]

      if (file.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) === null) {
        this.$toast.open({
          message: 'Không thể chọn tệp ' + file.name + ' do không hợp lệ',
          type: 'warning'
        })
        return
      }
      this.imgPut = file

      this.thumb_file = URL.createObjectURL(file)
    },
    async handlePostOrg () {
      try {
        this.isLoading = true

        this.form.logo = await this.uploadFile(
          this.imgPut,
          this.supplierInfo.id
        )

        const params = {
          address: this.form.contact,
          contact_point: this.form.phone_number,
          name: this.form.company_name,
          org_parent_id: this.supplierInfo?.org_id,
          supplier_id: this.supplierInfo?.id,
          logo: this.form.logo
        }

        await this.$rf.getRequest('DoctorRequest').createProcessor(params)

        this.$toast.open({
          message: this.$t('lbl_add_processor_success'),
          type: 'success'
        })

        window.$('#ModalAddProcessor').modal('hide')

        this.$emit('onSuccess')
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('lbl_add_processor_error'),
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    async handleUpdateProcessor () {
      try {
        this.isLoading = true

        const params = {
          name: this.form.company_name,
          address: this.form.contact,
          contact_point: this.form.phone_number,
          parent_id: this.supplierInfo.org_id
        }
        if (this.imgPut) {
          params.logo = this.imgPut
        }
        const formData = new FormData()

        for (const key in params) {
          if (!isNil(params[key])) {
            formData.set(key, params[key])
          }
        }

        await this.$rf
          .getRequest('DoctorRequest')
          .postUpdateSubOrganization(this.processorInfo.org_id, formData)

        this.$toast.open({
          message: this.$t('lbl_update_processor_success'),
          type: 'success'
        })

        window.$('#ModalAddProcessor').modal('hide')

        this.$emit('onSuccess')
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('lbl_update_processor_error'),
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    handleSubmitOrg () {
      if (!this.form.company_name) {
        alert(this.$t('lbl_enter_all_data'))
        return
      }
      this.isUpdate ? this.handleUpdateProcessor() : this.handlePostOrg()
    },
    handleMapData () {
      this.form = {
        company_name: this.processorInfo?.org_info?.name,
        contact: this.processorInfo?.org_info?.address,
        phone_number: this.processorInfo?.org_info?.contact_point,
        logo: this.processorInfo.org_info?.logo
      }
      this.thumb_file = this.processorInfo.org_info?.logo
      if (!this.processorInfo?.org_info?.logo.includes('http')) {
        this.thumb_file = appUtils.getImageURL(
          this.processorInfo?.org_info?.logo
        )
      }
    },
    async uploadFile (file, id) {
      const params = {
        file,
        FolderTarget: `processorInfo`,
        IdTarget: id
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf
        .getRequest('DoctorRequest')
        .uploadFileTenant(formData)
      return res.data.fileUrl
    }
  }
}
</script>
  <style lang="scss" scoped>
::v-deep {
  .button-add {
    background-color: #20419b;
  }
  .select-form {
    appearance: none;
  }

  .arrow-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .el-select-owner {
    input {
      font-size: 18px !important;
      color: #41464b !important;
      font-weight: 400 !important;
      background-color: transparent !important;
      padding: 0 !important;
    }

    input::placeholder {
      font-size: 20px;
      color: #868181;
      font-weight: 400;
    }
  }
}
</style>
  